@import url("https://fonts.googleapis.com/css2?family=Roboto:display=swap");

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
* {
  box-sizing: border-box;
}
.app {
  position: relative;
  min-height: 100vh;
}

/* nav bar */

.logoImg {
  margin-left: 20px;
  margin-right: -20px;
  height: 50px;
  object-fit: cover;
}
.navBar {
  height: 70px;
  display: flex;
  justify-content: space-between;
}
.sideNav {
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;
}
.navLink {
  text-decoration: none;
  font-weight: bold;
  color: #00244b;
  padding: 10px 10px 0 10px;
}
.navLink:visited {
  text-decoration: none;
  color: #00244b;
}
.linkText {
  font-size: 14px;
  padding: 2px;
}

.linkText:hover {
  border-radius: 3px;
  padding: 5px 8px 5px 8px;
  margin-top: 10px;
  color: white;
  background-color: #00244b;
}

/* contact link */

.aLink {
  text-decoration: none;
  font-weight: bold;
  background-color: #00244b;
  padding: 12px 10px 0 10px;
  font-size: 14px;
  color: white;
}
.aLink:hover {
  opacity: 0.8;
}

.aLink:visited {
  color: white;
}

/* home page */

.quote {
  font-size: 30px;
  font-weight: 200;
  text-align: center;
  font-style: italic;
  margin-bottom: 10px;
  color: #00244b;
}
.homepage {
  display: flex;
  flex-direction: column;
}
.titleBar {
  height: 24px;
  width: 100%;
  color: white;
  background-image: linear-gradient(to right, #2947ad, #00244b);
  font-size: 125%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.makA_rev {
  height: 50px;
  opacity: 0.13;
  margin-top: -24px;
  margin-bottom: -5px;
}

/* phone */

.phoneIcon {
  margin-right: 10px;
  color: #2947ad;
}

.phoneLink {
  display: flex;
  justify-content: center;
  color: #00244b;
  font-size: 23px;
  font-weight: bold;
  text-decoration: none;
  padding: 2px;
  border-radius: 10px;
}

.phoneLink:visited {
  text-decoration: none;
}
.phoneLink:hover {
  color: white;
}
.phoneDiv {
  margin-bottom: 10px;
}
.phonePara {
  color: #00244b;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
}
/* bathroom/kitchenette selector page */

.subLink {
  text-align: center;
  text-decoration: none;
  color: #00244b;
}
.subLink:visited {
  text-decoration: none;
  color: #00244b;
}
.subLink:hover {
  color: white;
  text-shadow: 2px 2px 5px #00244b;
}
.selectorTitle {
  font-weight: 300;
  color: #00244b;
}
.selectorSub {
  margin-top: 0;
}

/* picture gallery */
.subLinkDivPage {
  position: relative;
  top: 1rem;
}
.pic {
  height: 130px;
  width: 210px;
  object-fit: cover;
  margin: 10px;
}
.pic:hover {
  -webkit-box-shadow: 1px 1px 11px 1px #00244b;
  box-shadow: 1px 1px 11px 1px #00244b;
}
.gallery {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 8% 5% 10% 5%;
}
.descriptionDiv {
  text-align: left;
  padding: 1% 20%;
  margin: 4% 0;
  color: #00244b;
  background: rgba(255, 255, 255, 0.3);
}
.dList {
  list-style-type: disc;
}
.phoneStaticDiv {
  background: rgba(255, 255, 255, 0.3);
  padding: 3% 0%;
}

/* -----------------hamburger menu----------------- */

.hamDiv {
  display: none;
}

#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: white;
  transition: color 0.2s;
  font-weight: bold;
}

/* Change color on hover */

.burgerHomeLink:hover {
  text-decoration: underline;
}
/* .bm-item:hover {
  text-decoration: underline;
} */
.bm-item:focus {
  outline: none;
}

/* Position and sizing of burger button  */
.bm-burger-button {
  position: absolute;
  width: 30px;
  height: 24px;
  right: 36px;
  top: 23px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #00244b;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #2947ad;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 25px;
  width: 25px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
  width: 3px !important;
  height: 20px !important;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background-image: linear-gradient(to top left, #2947ad, #00244b);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #2947ad;
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
  overflow-y: hidden;
}
.mainSiteLink {
  opacity: 0.1;
}
.makA {
  margin: 20px 0 0 10px;
  width: 130px;
}
/* back button  */

.backImage {
  height: 25px;
  opacity: 0.5;
}
.backText {
  color: #2947ad;
  font-size: 8px;
  margin-top: 8px;
  text-transform: uppercase;
}
.BackButtonDiv {
  position: absolute;
  display: flex;
  margin-top: -25px;
  left: 5px;
}
/* .bm-item-list:focus {
  outline: none;
} */

/* Styling of overlay */
/* .bm-overlay {
} */
.mainSite {
  text-align: center;
  margin-left: 10px;
  font-size: 12px;
}
.mainSiteLink:hover {
  opacity: 1;
}

/* media queries */
@media (min-width: 1000px) {
  .pic {
    height: 170px;
    width: 270px;
  }
  .gallery {
    padding: 8% 5% 10% 5%;
  }
}
@media (min-width: 1200px) {
  .pic {
    height: 190px;
    width: 300px;
  }
  .gallery {
    padding: 8% 5% 10% 5%;
  }
}
@media (min-width: 1400px) {
  .pic {
    height: 210px;
    width: 350px;
  }

  .gallery {
    padding: 8% 5% 10% 5%;
  }
}
@media screen and (max-width: 1000px) {
  .hamDiv {
    display: block;
  }
  .sideNav {
    display: none;
  }
}
@media screen and (max-width: 650px) {
  .logoImg {
    margin-top: 8px;
    height: 40px;
  }
  .phoneLink {
    font-size: 18px;
  }
  .phonePara {
    font-size: 14px;
  }
  .descriptionDiv {
    text-align: left;
    padding: 1% 10%;
    margin: 4% 0;
    color: #00244b;
    background: rgba(255, 255, 255, 0.3);
  }
}
