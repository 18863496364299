.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0;
  padding: 20px 30px;
}
.img-item {
  flex: 0.1 0 24%; /* tweak the thrid value to adjust the vertical gutters */
  /* background: #fff; */
  margin-bottom: 0px;
}
.img-item:hover{
    cursor: pointer;
}
/* .img-wrap {
  object-fit: cover;
} */
.img-wrap img {
  width: 100%;
  height: auto;
  max-height: 25rem;
}

.img-hover-zoom {
  /* height: 300px; */
  /* [1.1] Set it as per your need  */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform 0.5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.5);
}
@media (min-width: 1200px) {
  .wrapper {
    margin: 0 5%;
  }
}
@media (min-width: 1400px) {
  .wrapper {
    margin: 0 10%;
  }
}

@media (max-width: 1100px) {
  .img-item {
    flex: 0.2 10 31%; /* 3 across */
  }
}
@media (max-width: 740px) {
  .img-item {
    flex: 0.1 0 48%; /* 2 across */
  }
  .wrapper {
    padding: 10px;
  }
}
@media (max-width: 370px) {
  .img-item {
    flex: 0 0 100%; /* 1 accross */
  }
  .wrapper {
    padding: 15px;
  }
}
