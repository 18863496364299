@import url(https://fonts.googleapis.com/css2?family=Roboto:display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
* {
  box-sizing: border-box;
}
.app {
  position: relative;
  min-height: 100vh;
}

/* nav bar */

.logoImg {
  margin-left: 20px;
  margin-right: -20px;
  height: 50px;
  object-fit: cover;
}
.navBar {
  height: 70px;
  display: flex;
  justify-content: space-between;
}
.sideNav {
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;
}
.navLink {
  text-decoration: none;
  font-weight: bold;
  color: #00244b;
  padding: 10px 10px 0 10px;
}
.navLink:visited {
  text-decoration: none;
  color: #00244b;
}
.linkText {
  font-size: 14px;
  padding: 2px;
}

.linkText:hover {
  border-radius: 3px;
  padding: 5px 8px 5px 8px;
  margin-top: 10px;
  color: white;
  background-color: #00244b;
}

/* contact link */

.aLink {
  text-decoration: none;
  font-weight: bold;
  background-color: #00244b;
  padding: 12px 10px 0 10px;
  font-size: 14px;
  color: white;
}
.aLink:hover {
  opacity: 0.8;
}

.aLink:visited {
  color: white;
}

/* home page */

.quote {
  font-size: 30px;
  font-weight: 200;
  text-align: center;
  font-style: italic;
  margin-bottom: 10px;
  color: #00244b;
}
.homepage {
  display: flex;
  flex-direction: column;
}
.titleBar {
  height: 24px;
  width: 100%;
  color: white;
  background-image: linear-gradient(to right, #2947ad, #00244b);
  font-size: 125%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.makA_rev {
  height: 50px;
  opacity: 0.13;
  margin-top: -24px;
  margin-bottom: -5px;
}

/* phone */

.phoneIcon {
  margin-right: 10px;
  color: #2947ad;
}

.phoneLink {
  display: flex;
  justify-content: center;
  color: #00244b;
  font-size: 23px;
  font-weight: bold;
  text-decoration: none;
  padding: 2px;
  border-radius: 10px;
}

.phoneLink:visited {
  text-decoration: none;
}
.phoneLink:hover {
  color: white;
}
.phoneDiv {
  margin-bottom: 10px;
}
.phonePara {
  color: #00244b;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
}
/* bathroom/kitchenette selector page */

.subLink {
  text-align: center;
  text-decoration: none;
  color: #00244b;
}
.subLink:visited {
  text-decoration: none;
  color: #00244b;
}
.subLink:hover {
  color: white;
  text-shadow: 2px 2px 5px #00244b;
}
.selectorTitle {
  font-weight: 300;
  color: #00244b;
}
.selectorSub {
  margin-top: 0;
}

/* picture gallery */
.subLinkDivPage {
  position: relative;
  top: 1rem;
}
.pic {
  height: 130px;
  width: 210px;
  object-fit: cover;
  margin: 10px;
}
.pic:hover {
  box-shadow: 1px 1px 11px 1px #00244b;
}
.gallery {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 8% 5% 10% 5%;
}
.descriptionDiv {
  text-align: left;
  padding: 1% 20%;
  margin: 4% 0;
  color: #00244b;
  background: rgba(255, 255, 255, 0.3);
}
.dList {
  list-style-type: disc;
}
.phoneStaticDiv {
  background: rgba(255, 255, 255, 0.3);
  padding: 3% 0%;
}

/* -----------------hamburger menu----------------- */

.hamDiv {
  display: none;
}

#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: white;
  transition: color 0.2s;
  font-weight: bold;
}

/* Change color on hover */

.burgerHomeLink:hover {
  text-decoration: underline;
}
/* .bm-item:hover {
  text-decoration: underline;
} */
.bm-item:focus {
  outline: none;
}

/* Position and sizing of burger button  */
.bm-burger-button {
  position: absolute;
  width: 30px;
  height: 24px;
  right: 36px;
  top: 23px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #00244b;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #2947ad;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 25px;
  width: 25px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
  width: 3px !important;
  height: 20px !important;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background-image: linear-gradient(to top left, #2947ad, #00244b);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #2947ad;
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
  overflow-y: hidden;
}
.mainSiteLink {
  opacity: 0.1;
}
.makA {
  margin: 20px 0 0 10px;
  width: 130px;
}
/* back button  */

.backImage {
  height: 25px;
  opacity: 0.5;
}
.backText {
  color: #2947ad;
  font-size: 8px;
  margin-top: 8px;
  text-transform: uppercase;
}
.BackButtonDiv {
  position: absolute;
  display: flex;
  margin-top: -25px;
  left: 5px;
}
/* .bm-item-list:focus {
  outline: none;
} */

/* Styling of overlay */
/* .bm-overlay {
} */
.mainSite {
  text-align: center;
  margin-left: 10px;
  font-size: 12px;
}
.mainSiteLink:hover {
  opacity: 1;
}

/* media queries */
@media (min-width: 1000px) {
  .pic {
    height: 170px;
    width: 270px;
  }
  .gallery {
    padding: 8% 5% 10% 5%;
  }
}
@media (min-width: 1200px) {
  .pic {
    height: 190px;
    width: 300px;
  }
  .gallery {
    padding: 8% 5% 10% 5%;
  }
}
@media (min-width: 1400px) {
  .pic {
    height: 210px;
    width: 350px;
  }

  .gallery {
    padding: 8% 5% 10% 5%;
  }
}
@media screen and (max-width: 1000px) {
  .hamDiv {
    display: block;
  }
  .sideNav {
    display: none;
  }
}
@media screen and (max-width: 650px) {
  .logoImg {
    margin-top: 8px;
    height: 40px;
  }
  .phoneLink {
    font-size: 18px;
  }
  .phonePara {
    font-size: 14px;
  }
  .descriptionDiv {
    text-align: left;
    padding: 1% 10%;
    margin: 4% 0;
    color: #00244b;
    background: rgba(255, 255, 255, 0.3);
  }
}

/* footer */

main {
  padding-bottom: 19rem;
}
main::after {
  content: "";
  background: url(/static/media/basement_bg_bw.44334c28.jpg);
  opacity: 0.4;
  top: 70px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  background-position: center;
  background-size: cover;
}

.footerDiv {
  background-image: linear-gradient(to bottom, #2947ad, #00244b);
  /* background-color: #2947ad; */
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 19rem;
  display: flex;
  justify-content: space-around;
}
.footLogoDiv {
  margin-top: 2rem;
  height: 8rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.footLogo {
  top: 3rem;
  height: 3rem;
  object-fit: cover;
}
.fbPara {
  color: white;
  font-style: italic;
  font-size: 10px;
  margin-top: 8px;
}
.fbPara:visited {
  color: white;
  text-decoration: none;
}
.fbPara:hover {
  color: black;
}
.fbDiv {
  text-decoration: none;
  display: flex;
}

.fbLogo {
  margin: 5px 8px 0 0;
  height: 2rem;
}
.footMapDiv {
  display: flex;
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.footMapLogo {
  margin-top: 2rem;
  height: 2rem;
}
.addressDiv {
  color: white;
  font-size: 14px;
  margin: 30px -10px 0 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.google-map-code {
  width: 260px;
  line-height: 0;
  margin: 15px -10px 0 10px;
  box-shadow: 5px 5px 0px 0px black;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.resDiv {
  margin-top: 3rem;
}
.resources {
  color: white;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}
li {
  list-style-type: none;
}
.footLink {
  line-height: 1.5;
  text-decoration: none;
  padding: 2px;
  color: white;
  text-decoration: none;
}
.footLink:hover {
  color: black;
  text-decoration: none;
}
.addressDiv:hover {
  color: black;
}
.houzzDiv {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 35px -80px 0 80px;
}
.houzzPic {
  height: 60px;
}
.houzzWrap {
  display: flex;
}
.copyright {
  position: absolute;
  left: 50%;
  color: rgb(170, 170, 170);
  font-size: 9px;
  bottom: 0px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  margin: 0;
  width: 100vw;
}
@media screen and (max-width: 880px) {
  main {
    padding-bottom: 28rem;
  }
  .addressDiv {
    font-size: 10px;
  }
  .google-map-code {
    margin-left: -5px;
    /* -webkit-box-shadow: none;
    box-shadow: none; */
  }
  .resDiv {
    margin-left: -40px;
  }
  .houzzDiv {
    margin: 15px -70px 0 50px;
  }
  iframe {
    width: 210px;
    height: 160px;
  }
  .resources {
    font-size: 10px;
    margin-left: 5px;
  }
  .footMapDiv {
    margin: 15px 0 5px 0;
  }
  .footLink {
    font-size: 12px;
  }
  .houzzPic {
    height: 48px;
  }
  .footMapLogo {
    margin-left: -10px;
  }
}
@media screen and (max-width: 650px) {
  .footerDiv {
    height: 28rem;
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding-left: 15%;
  }
  .footLogo {
    top: 3rem;
    height: 2rem;
    object-fit: cover;
  }
  .footLogoDiv {
    margin-top: -1rem;
  }
  .footMapDiv {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: -30px -1rem 5px 1rem;
  }
  .houzzDiv {
    margin: 50px 0px 0 280px;
  }
  .footMapDiv {
    margin: -200px 0px 0 0px;
  }
  .addressDiv {
    font-size: 10px;
    margin: 30px -10px 0 10px;
  }
  .google-map-code {
    width: 210px;
    margin: 15px -20px 0 20px;
  }
  iframe {
    width: 210px;
    height: 160px;
  }
  .resDiv {
    margin: -290px -240px 0 240px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .resources {
    font-size: 10px;
    margin-left: 5px;
  }
  li {
    list-style-type: none;
  }
  .footLink {
    font-size: 12px;
  }
  hr {
    margin-left: 0px;
    width: 200px;
  }
  .footMapLogo {
    margin-left: 0px;
  }

  .houzzWrap {
    flex-direction: column;
  }
}
@media screen and (max-width: 550px) {
  .footerDiv {
    padding-left: 10%;
  }
  .backText {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .houzzDiv {
    margin: 70px -260px 0 260px;
  }
  .resDiv {
    margin: -290px -220px 0 220px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .footerDiv {
    padding-left: 6%;
  }
}
@media screen and (max-width: 400px) {
  .houzzDiv {
    margin: 70px -250px 0 250px;
  }
  .resDiv {
    margin: -290px -215px 0 215px;
  }
  .footerDiv {
    padding-left: 2%;
  }
  .copyright {
    font-size: 8px;
  }
}
@media screen and (max-width: 361px) {
  .houzzDiv {
    margin: 70px -250px 0 250px;
  }
  .resDiv {
    margin: -290px -205px 0 205px;
  }
  .footerDiv {
    padding-left: 0.5%;
  }
}
@media screen and (max-width: 340px) {
  .houzzDiv {
    margin: 50px -250px 0 250px;
  }
  .resDiv {
    margin: -290px -185px 0 185px;
  }
  .houzzTop {
    display: flex;
    flex-direction: column;
  }
  .houzzBottom {
    display: flex;
    flex-direction: column;
  }
  .houzzPic {
    width: 40px;
    height: auto;
  }
  .resources {
    font-size: 9px;
  }
  .footLink {
    font-size: 10px;
  }
  .logoImg {
    margin-top: 8px;
    height: 32px;
  }
  .copyright {
    font-size: 7px;
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0;
  padding: 20px 30px;
}
.img-item {
  flex: 0.1 0 24%; /* tweak the thrid value to adjust the vertical gutters */
  /* background: #fff; */
  margin-bottom: 0px;
}
.img-item:hover{
    cursor: pointer;
}
/* .img-wrap {
  object-fit: cover;
} */
.img-wrap img {
  width: 100%;
  height: auto;
  max-height: 25rem;
}

.img-hover-zoom {
  /* height: 300px; */
  /* [1.1] Set it as per your need  */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}
@media (min-width: 1200px) {
  .wrapper {
    margin: 0 5%;
  }
}
@media (min-width: 1400px) {
  .wrapper {
    margin: 0 10%;
  }
}

@media (max-width: 1100px) {
  .img-item {
    flex: 0.2 10 31%; /* 3 across */
  }
}
@media (max-width: 740px) {
  .img-item {
    flex: 0.1 0 48%; /* 2 across */
  }
  .wrapper {
    padding: 10px;
  }
}
@media (max-width: 370px) {
  .img-item {
    flex: 0 0 100%; /* 1 accross */
  }
  .wrapper {
    padding: 15px;
  }
}

