/* footer */

main {
  padding-bottom: 19rem;
}
main::after {
  content: "";
  background: url("./Images/basement_bg_bw.jpg");
  opacity: 0.4;
  top: 70px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  background-position: center;
  background-size: cover;
}

.footerDiv {
  background-image: linear-gradient(to bottom, #2947ad, #00244b);
  /* background-color: #2947ad; */
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 19rem;
  display: flex;
  justify-content: space-around;
}
.footLogoDiv {
  margin-top: 2rem;
  height: 8rem;
  width: fit-content;
}
.footLogo {
  top: 3rem;
  height: 3rem;
  object-fit: cover;
}
.fbPara {
  color: white;
  font-style: italic;
  font-size: 10px;
  margin-top: 8px;
}
.fbPara:visited {
  color: white;
  text-decoration: none;
}
.fbPara:hover {
  color: black;
}
.fbDiv {
  text-decoration: none;
  display: flex;
}

.fbLogo {
  margin: 5px 8px 0 0;
  height: 2rem;
}
.footMapDiv {
  display: flex;
  text-decoration: none;
  width: fit-content;
}
.footMapLogo {
  margin-top: 2rem;
  height: 2rem;
}
.addressDiv {
  color: white;
  font-size: 14px;
  margin: 30px -10px 0 10px;
  width: fit-content;
}

.google-map-code {
  width: 260px;
  line-height: 0;
  margin: 15px -10px 0 10px;
  -webkit-box-shadow: 5px 5px 0px 0px black;
  box-shadow: 5px 5px 0px 0px black;
  width: fit-content;
}
.resDiv {
  margin-top: 3rem;
}
.resources {
  color: white;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}
li {
  list-style-type: none;
}
.footLink {
  line-height: 1.5;
  text-decoration: none;
  padding: 2px;
  color: white;
  text-decoration: none;
}
.footLink:hover {
  color: black;
  text-decoration: none;
}
.addressDiv:hover {
  color: black;
}
.houzzDiv {
  width: fit-content;
  margin: 35px -80px 0 80px;
}
.houzzPic {
  height: 60px;
}
.houzzWrap {
  display: flex;
}
.copyright {
  position: absolute;
  left: 50%;
  color: rgb(170, 170, 170);
  font-size: 9px;
  bottom: 0px;
  transform: translate(-50%, -50%);
  text-align: center;
  margin: 0;
  width: 100vw;
}
@media screen and (max-width: 880px) {
  main {
    padding-bottom: 28rem;
  }
  .addressDiv {
    font-size: 10px;
  }
  .google-map-code {
    margin-left: -5px;
    /* -webkit-box-shadow: none;
    box-shadow: none; */
  }
  .resDiv {
    margin-left: -40px;
  }
  .houzzDiv {
    margin: 15px -70px 0 50px;
  }
  iframe {
    width: 210px;
    height: 160px;
  }
  .resources {
    font-size: 10px;
    margin-left: 5px;
  }
  .footMapDiv {
    margin: 15px 0 5px 0;
  }
  .footLink {
    font-size: 12px;
  }
  .houzzPic {
    height: 48px;
  }
  .footMapLogo {
    margin-left: -10px;
  }
}
@media screen and (max-width: 650px) {
  .footerDiv {
    height: 28rem;
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding-left: 15%;
  }
  .footLogo {
    top: 3rem;
    height: 2rem;
    object-fit: cover;
  }
  .footLogoDiv {
    margin-top: -1rem;
  }
  .footMapDiv {
    width: fit-content;
    margin: -30px -1rem 5px 1rem;
  }
  .houzzDiv {
    margin: 50px 0px 0 280px;
  }
  .footMapDiv {
    margin: -200px 0px 0 0px;
  }
  .addressDiv {
    font-size: 10px;
    margin: 30px -10px 0 10px;
  }
  .google-map-code {
    width: 210px;
    margin: 15px -20px 0 20px;
  }
  iframe {
    width: 210px;
    height: 160px;
  }
  .resDiv {
    margin: -290px -240px 0 240px;
    width: fit-content;
  }
  .resources {
    font-size: 10px;
    margin-left: 5px;
  }
  li {
    list-style-type: none;
  }
  .footLink {
    font-size: 12px;
  }
  hr {
    margin-left: 0px;
    width: 200px;
  }
  .footMapLogo {
    margin-left: 0px;
  }

  .houzzWrap {
    flex-direction: column;
  }
}
@media screen and (max-width: 550px) {
  .footerDiv {
    padding-left: 10%;
  }
  .backText {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .houzzDiv {
    margin: 70px -260px 0 260px;
  }
  .resDiv {
    margin: -290px -220px 0 220px;
    width: fit-content;
  }
  .footerDiv {
    padding-left: 6%;
  }
}
@media screen and (max-width: 400px) {
  .houzzDiv {
    margin: 70px -250px 0 250px;
  }
  .resDiv {
    margin: -290px -215px 0 215px;
  }
  .footerDiv {
    padding-left: 2%;
  }
  .copyright {
    font-size: 8px;
  }
}
@media screen and (max-width: 361px) {
  .houzzDiv {
    margin: 70px -250px 0 250px;
  }
  .resDiv {
    margin: -290px -205px 0 205px;
  }
  .footerDiv {
    padding-left: 0.5%;
  }
}
@media screen and (max-width: 340px) {
  .houzzDiv {
    margin: 50px -250px 0 250px;
  }
  .resDiv {
    margin: -290px -185px 0 185px;
  }
  .houzzTop {
    display: flex;
    flex-direction: column;
  }
  .houzzBottom {
    display: flex;
    flex-direction: column;
  }
  .houzzPic {
    width: 40px;
    height: auto;
  }
  .resources {
    font-size: 9px;
  }
  .footLink {
    font-size: 10px;
  }
  .logoImg {
    margin-top: 8px;
    height: 32px;
  }
  .copyright {
    font-size: 7px;
  }
}
